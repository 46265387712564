export const FirebaseConfig = {
	"projectId": "fiara-966cb",
	"appId": "1:754871502626:web:7fd16f1cc1d204c8e6477b",
	"databaseURL": "https://fiara-966cb-default-rtdb.firebaseio.com",
	"storageBucket": "fiara-966cb.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBL1ogseHL5GZK7AJUUJ8Cxni477FspSRY",
	"authDomain": "fiara-966cb.firebaseapp.com",
	"messagingSenderId": "754871502626",
	"measurementId": "G-FWFWPFZYHP"
};